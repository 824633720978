.slideshow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  .tint {
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: background-color .6s ease;
    will-change: background-color;
  }
}

.slideshow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity .6s ease;
  will-change: opacity;

  &.is-ready {
    opacity: 1;
  }

  .slider-is-ready & {
    opacity: 1;
  }

  &__slide {
    position: relative;
    width: 100vw;
    height: 100vh;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: block;
      max-width: 60vw;
      max-height: 70vh;
    }

    .img {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity .3s ease;

      &.is-loaded {
        opacity: 1;
      }
    }
  }
}
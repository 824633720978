.formule {
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 1;
  transition: opacity .6s ease;
  padding-bottom: 60px;

  @media (max-width: 560px) {
    padding-bottom: 120px;
  }

  .panel-is-open & {
    opacity: 0;
  }

  &__title {
    margin: 0;
    text-align: center;
    padding: 120px 0 20px;
    @include brand-serif();
    font-size: 12vw;

    @media (min-width: 768px) {
      padding: 128px 0 40px;
      font-size: 5vw;
    }
  }

  &__programme {
    max-width: 90vw;
    margin: 0 auto 20px;
    font-size: 16px;
    text-align: center;

    @media (min-width: 768px) {
      max-width: 60vw;
      font-size: 20px;
      margin: 0 auto 40px;
    }

    .slot {
      margin: 20px 0 40px;

      @media (min-width: 768px) {
        margin: 40px 0 80px;
      }

      &__title {
        font-weight: normal;
        text-decoration: underline;
        margin: .5em auto;
        max-width: 100%;
        display: inline-block;

        @media (min-width: 768px) {
          max-width: 80%;
        }

        @media (min-width: 1280px) {
          max-width: 60%;
        }
      }
      
      &__time {
        display: block;
        margin: 20px auto 0;

        span {
          display: inline-block;
          padding: 2px 9px;
          font-size: 16px;
          line-height: 24px;
          vertical-align: middle;
          background-color: #FFF;
          color: #000;
          border-radius: 14px;
        }
      }

      h1,h2,h3,h4,h5 {
        font-weight: normal;
        text-decoration: underline;
        margin: .5em 0;
        font-size: 16px;

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }
  
      p {
        margin: .5em 0 1em;

        @media (min-width: 768px) {
          margin: .5em 40px 1em;
        }

        @media (min-width: 1280px) {
          margin: .5em 80px 1em;
        }
      }
  
      ul, ol {
        list-style: inside;
        padding: 0;
        border-bottom: 0;
  
        li {
          text-transform: initial;
        }
      }
    }
  }

  &__meta-list {
    max-width: 85%;
    font-size: 16px;
    margin: 0 auto 40px;
    padding: 0;
    list-style: none;
    border: 2px solid;
    border-bottom: 0;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 768px) {
      max-width: 60%;
      font-size: 20px;
      margin: 0 auto 60px;
    }

    li {
      padding: 10px 0;
      border-bottom: 2px solid;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      svg {
        display: inline-block;
        line-height: 16px;
        height: 24px;
        width: auto;
        margin-left: 5px;
        vertical-align: middle;

        @media (min-width: 768px) {
          line-height: 20px;
          height: 28px;
          margin-left: 10px;
        }
      }

      &.formule__book {
        padding: 0;

        svg {
          g {
            fill: #FFF;

            rect, polyline {
              stroke: #FFF;
            }
          }
        }

        &:hover {
          background-color: #FFF;
          color: #000;
          cursor: pointer;
          border-bottom: 2px solid #FFF;

          svg {
            g {
              fill: #000;

              rect, polyline {
                stroke: #000;
              }
            }
          }
        }

        a {
          padding: 10px 0;
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
  transition: opacity .6s !important;
}

#nprogress .bar {
  background: #000;

  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}


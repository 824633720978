.formules-panel {
  right: 0;
  transform: translateX(100%);

  .panel__exit-button {
    right: 0;
    top: 0;
  }
  
  &__list {
    width: 100%;
    list-style: none;
    padding: 40vw 0;
    margin: 0;

    @media (min-width: 768px) {
      padding: 15vw 0;
    }

    &-item {
      @include brand-serif();
      text-align: center;
      font-size: 10vw;
      line-height: 1.2em;
      color: #000;

      @media (min-width: 768px) {
        font-size: 5vw;
      }

      .no-touchevents &:hover {
        a {
          display: inline-block;
          line-height: 0.8em;
          border-bottom: 1px solid #000;
        }
      }

      &.is-active {
        a {
          display: inline-block;
          line-height: 0em;
          border-bottom: 2px solid;
        }
        
        .no-touchevents &:hover {
          a {
            line-height: 0em;
            border-bottom: 2px solid;
          }
        }
      }
    }
  }

  .previews {
    transform: translateX(-100%);
    left: 0;

    &__inner {
      text-align: center;
      font-size: 0;

      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
      }
    }

    .summary {
      font-size: 28px;
      width: 90%;
      border: 2px solid;
      display: inline-block;
      vertical-align: middle;

      & > div {
        padding: 20px 0;
        text-transform: uppercase;

        &:first-of-type {
          border-bottom: 2px solid;
        }
      }
    }
  }
}
.portfolio-panel {
  left: 0;
  transform: translateX(-100%);
  color: #000;

  .panel__exit-button {
    left: 0;
    top: 0;
  }

  .filter {
    position: absolute;
    top: 20vw;
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    border-right: 0;
    z-index: 40;

    @media (min-width: 768px) {
      top: 8vw;
    }

    &__option {
      width: calc(50% - 1px);
      float: left;
      padding: 10px 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.8vw;
      background-color: rgba(248,248,248,0.9);
      border-bottom: 2px solid #eee;
      letter-spacing: 1px;

      &:hover {
        border-bottom: 2px solid #000;
        cursor: pointer;
      }

      &.is-active {
        border-bottom: 2px solid #000;
      }
    }
  }

  &__list {
    width: 100%;
    list-style: none;
    padding: 40vw 0;
    margin: 0;

    @media (min-width: 768px) {
      padding: 15vw 0;
    }

    &[data-filtered-by="commercial"] {
      .portfolio-panel__list-item.commercial {
        display: block;
      }
    }

    &[data-filtered-by="personal-shopping"] {
      .portfolio-panel__list-item.personal-shopping {
        display: block;
      }
    }

    &-item {
      display: none;
      @include brand-serif();
      text-align: center;
      font-size: 8vw;
      line-height: 1.2em;
      transition: border-bottom .3s ease;

      @media (min-width: 768px) {
        font-size: 3.5vw;
      }

      &.is-active {
        a {
          display: inline-block;
          line-height: 0em;
          border-bottom: 2px solid;
        }
        
        .no-touchevents &:hover {
          a {
            line-height: 0em;
            border-bottom: 2px solid;
          }
        }
      }

      &:hover {
        a {
          display: inline-block;
          line-height: 0.8em;
          border-bottom: 1px solid #000;
        }
      }
    }
  }

  .previews {
    transform: translateX(100%);
    right: 0;
    color: #FFF;
  }
}

.portfolio {
  &.slider-is-ready {
    opacity: 1;

    .loading {
      opacity: 0;
    }
  }

  .loading {
    transition: opacity .6s ease;
  }

  &:not(.slider-is-ready) .loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    text-align: center;
    animation-name: visibility;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: ease;

    @keyframes visibility {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      width: 0;
    }

    & > div {
      display: inline-block;
      @include brand-serif();
      font-size: 5vw;
    }
  }
}
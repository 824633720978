.previews {
  position: fixed;
  z-index: 5;
  top: 0;
  width: 50vw;
  height: 100vh;
  display: none;

  .panel-is-open &.is-visible {
    display: block;
  }

  &__inner {
    right: 0;
    position: relative;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      max-width: 80%;
      max-height: 70%;
      opacity: 0;

      &.is-visible {
        opacity: 1;
      }
    }
  }
}
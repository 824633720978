::selection {
  background: #000;
  color: #FFF;
}

@mixin brand-serif {
  font-family: "Goldenbook-Light", serif;
  font-weight: 200;
  text-transform: uppercase;
}

html, body {
  background: #000;
}

body {
  color: #FFF;
  margin: 0;
  padding: 0;  
  font-size: 20px;
  font-family: 'helvetica Neue', helvetica, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;

  @media(min-width: 768px) {
    font-size: 1.9vw;
  }

  &:not([data-play-state="is-active"]) {
    .slideshow-container {  
      .tint {
        background-color: rgba(0,0,0,0.7);
      }
    }
  }

  &.portfolio-is-open {
    .portfolio-panel {
      transform: translateX(0);
    }

    .nav-item--portfolio {
      left: 50%;
      color: #000;
      transform: translateX(-50%);

      @media (max-width: 560px) {
        display: block !important;
      }

      @media(min-width: 768px) {
        left: 25%;
      }
    }
  }

  &.formules-is-open {
    .formules-panel {
      transform: translateX(0);
    }

    .nav-item--formules {
      right: 50%;
      color: #000;
      transform: translateX(50%);

      @media (max-width: 560px) {
        display: block !important;
      }

      @media(min-width: 768px) {
        right: 25%;
      }
    }
  }

  &.about-is-open {
    .about-panel {
      transform: translateX(0);
    }

    .nav-item--about {
      left: 50%;
      color: #000;
      transform: translateX(-50%);

      @media (max-width: 560px) {
        display: block !important;
      }

      @media(min-width: 768px) {
        left: 25%;
      }
    }
  }

  &.contact-is-open {
    .contact-panel {
      transform: translateX(0);
    }

    .nav-item--contact {
      right: 50%;
      color: #000;
      transform: translateX(50%);

      @media (max-width: 560px) {
        display: block !important;
      }

      @media(min-width: 768px) {
        right: 25%;
      }
    }
  }

  &.panel-is-open {
    .site-title {
      opacity: 0;
      transition: opacity .6s ease 0s;
    }

    .slideshow-container {  
      .tint {
        background-color: rgba(0,0,0,0.7);
      }
    }

    #portfolio-slideshow {    
      opacity: 0;
    }

    @media (max-width: 560px) {
      .nav-item {
        display: none;
      }
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.site-title {
  opacity: 1;
  will-change: opacity;
  transition: opacity .6s ease .6s;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  text-align: center;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
  }

  h1 {
    line-height: .95em;
    text-align: center;
    font-size: 14vw;
    display: inline-block;
    vertical-align: middle;
    color: white;
    @include brand-serif();

    @media(min-width: 768px) {
      font-size: 8vw;
    }

    .kern {
      &--s {
        margin-left: -0.5vw;
        margin-right: -0.5vw;
      }
      &--h {
        margin-left: -0.5vw;
      }
    }
  }
}

.nav-item {
  color: inherit;
  text-decoration: none;
  letter-spacing: 2px;
  text-transform: upppercase;
  font-size: 16px;
  z-index: 200;
  transition: left .6s cubic-bezier(.59,.01,.28,1), right .6s cubic-bezier(.59,.01,.28,1), color .6s ease .3s, transform .6s cubic-bezier(.59,.01,.28,1);

  @media(min-width: 560px) {
    font-size: inherit;
  }
}

.pos {
  position: fixed;
  padding: 15px;
  margin: auto;
  display: block;

  @media(min-width: 560px) {
    padding: 20px;
  }

  span {
    .no-touchevents &:hover {
      border-bottom: 2px solid;
      cursor: pointer;
    }
  }

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: 0;
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }
}

.exit {
  z-index: 5;
  text-align: center;

  @media (max-width: 560px) {
    top: 40px;
  }

  body[data-play-state="is-active"] & {
    display: none;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

main {
  position: relative;
  z-index: 1;
}
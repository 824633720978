.panel {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100%;
  background: #FFF;
  z-index: 100;
  transition: transform .6s cubic-bezier(.59,.01,.28,1); //cubic-bezier(0, 0.7, 0.3, 1)
  will-change: transform;

  /* width */
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #FFF;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000;
  }

  @media(min-width: 768px) {
    width: 50vw;
  }

  &__inner {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &:before {
      content: "";
      width: calc(100% - 5px);
      height: 100px;
      position: fixed;
      top: 0;
      left: 0;
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
      z-index: 2;

      @media (min-width: 1024px) {
        height: 120px;
      }
    }
  }

  &__exit-button {
    position: fixed;
    padding: 15px;
    z-index: 3;
    cursor: pointer;
    opacity: 1;
    transition: opacity .3s ease;
    will-change: opacity;
    height: 20px;
    transform: translateZ(0);
    backface-visibility: hidden;

    @media(min-width: 561px) {
      padding: 20px;
    }

    svg {
      cursor: pointer;

      @media (max-width: 560px) {
        height: 23px;
      }
    }

    &:hover {
      opacity: .5;
    }
  }
}
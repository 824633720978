.about-panel {
  left: 0;
  transform: translateX(-100%);
  color: #000;

  .panel__inner {
    &::before {
      display: none;
    }
  }

  .panel__exit-button {
    left: 0;
    bottom: 10px;
  }

  &__text {
    padding: 10vw 0;
    font-weight: 400;
    margin: 0 5vw;
    text-align: center;
    font-size: 20px;

    @media(min-width: 768px) {
      font-size: 1.9vw;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: fixed;
    transform: translateX(100%);
    top: 0;
    right: 0;
    text-align: center;
    transition: opacity .6s ease 0s;
    opacity: 0;
    pointer-events: none;

    .about-is-open & {
      opacity: 1;
      transition: opacity .6s ease .6s;
    }

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      width: 0;
      height: 100%;
    }

    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 40%;
      max-height: 60%;
    }
  }
}